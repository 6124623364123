import { useCompanyMutations } from "@app/entities/company/mutations";
import { useCompanyQueries } from "@app/entities/company/queries";
import notification from "antd/lib/notification";

export const useCompaniesLoader = () => {
  const { addMemberToCompany, isMutationPending } = useCompanyMutations();
  const { getMemberCompanies } = useCompanyQueries();
  const { memberCompanies, isMemberCompaniesPending } = getMemberCompanies();

  const handleAddCompany = async (values: { [key: string]: string }) => {
    const memberActivationCode = values.memberActivationCode?.trim();
    if (!memberActivationCode) {
      return notification.error({ message: "Activation code is required" });
    }
    addMemberToCompany(memberActivationCode);
  };

  const adminRedirectUrl =
    process.env.NODE_ENV === "production"
      ? `https://admin.swarmreport.org/#/company`
      : process.env.NODE_ENV === "staging"
      ? `https://staging.admin.swarmreport.org/#/company`
      : `http://localhost:8080/#/company`;

  const isLoading = isMutationPending || isMemberCompaniesPending;
  return {
    handleAddCompany,
    adminRedirectUrl,
    memberCompanies,
    isLoading,
  };
};
