import { useCompanyQueries } from "@app/entities/company/queries";
import { useParams } from "react-router";

export const useSuccessLoader = () => {
  const { reportId } = useParams();
  const { getReportCompanies } = useCompanyQueries();
  const { isReportCompaniesPending, reportCompanies } = getReportCompanies({
    reportId: Number(reportId),
  });

  const isLoading = isReportCompaniesPending;
  return {
    isLoading,
    reportCompanies,
  };
};
