import notification from "antd/lib/notification";

import axios from "axios";

const isLive = ["production", "staging"].includes(process.env.NODE_ENV ?? "");

const axiosInstance = axios.create({
  baseURL: isLive ? `/api` : `http://localhost:8335/api`,
  withCredentials: true,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error?.response?.statusText || error?.message;
    notification.error({
      message: `Error: ${error?.response?.status ?? "unknown"}`,
      description: message,
    });
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      window.location.replace(`/`);
    }
    return Promise.reject(error); // Ensure the error is thrown to be handled by React Query
  }
);

export default axiosInstance;
