import { useReportQueries } from "@app/entities/report/queries";
import dayjs from "dayjs";

export const useClaimedReports = () => {
  const { getClaimedReports } = useReportQueries();
  const { claimedReports, isClaimedReportsPending } = getClaimedReports();

  const columns = [
    {
      title: "Date",
      dataIndex: "dateCreated",
      render: (dateCreated: string) =>
        dayjs(dateCreated).format("YYYY-MM-DD h:mm A"),
    },
    { title: "Claim Code", dataIndex: "claimCode" },
    { title: "Note", dataIndex: "note" },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      align: "right" as const,
      title: "Elevation",
      dataIndex: "elevation",
    },
    {
      title: "Association",
      dataIndex: "companyName",
    },
  ];

  const isLoading = isClaimedReportsPending;
  return { isLoading, columns, claimedReports };
};
