import { useMemberMutations } from "@app/entities/member/mutations";
import { useMemberQueries } from "@app/entities/member/queries";
import { T_MemberAvailability } from "@app/entities/member/types";
import notification from "antd/lib/notification";
import { useRef } from "react";

const DEBOUNCE_TIME = 500;

export const useAvailabilityLoader = () => {
  const { isMutationPending, updateAvailability } = useMemberMutations();
  const { getMemberAvailability } = useMemberQueries();
  const { memberAvailability, isAvailabilityPending } = getMemberAvailability();

  // Use ref to set a timer for debouncing
  const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);

  const debouncedUpdateAvailability = async ({
    key,
    value,
  }: {
    key: keyof T_MemberAvailability;
    value: string | boolean;
  }) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      if (key === "rangeInMiles" && Number(value) > 25) {
        return notification.error({
          description: "Range in miles cannot exceed 25.",
          message: "Error",
        });
      }
      if (key === "elevationMaximum" && Number(value) > 50) {
        return notification.error({
          description: "Elevation maximum cannot exceed 50.",
          message: "Error",
        });
      }
      updateAvailability({ key, value });
    }, DEBOUNCE_TIME);
  };

  const isLoading = isAvailabilityPending || isMutationPending;
  return {
    memberAvailability,
    isLoading,
    debouncedUpdateAvailability,
    updateAvailability,
  };
};
