import axiosInstance from "@app/axiosInstance";
import { T_MemberCompany, T_ReportCompany } from "@app/entities/company/types";
import { normalizeDates, removeEmptyFields } from "@app/modules/utilities";
import { useQuery } from "@tanstack/react-query";

export const useCompanyQueries = () => {
  const getMemberCompanies = () => {
    const { isPending: isMemberCompaniesPending, data: memberCompanies } =
      useQuery({
        queryKey: ["member", "companies"],
        queryFn: (): Promise<T_MemberCompany[]> =>
          axiosInstance
            .get("/member/company")
            .then((response) =>
              response.data.map(normalizeDates).map(removeEmptyFields)
            ),
        throwOnError: true,
      });

    return {
      memberCompanies,
      isMemberCompaniesPending,
    };
  };

  const getReportCompanies = ({ reportId }: { reportId: number }) => {
    const { data: reportCompanies, isPending: isReportCompaniesPending } =
      useQuery({
        queryKey: ["associations", reportId],
        queryFn: (): Promise<T_ReportCompany[]> =>
          axiosInstance
            .get(`/report/${reportId}/associations`)
            .then((response) =>
              response.data.map(normalizeDates).map(removeEmptyFields)
            ),
        throwOnError: true,
      });

    return {
      reportCompanies,
      isReportCompaniesPending,
    };
  };

  return {
    getMemberCompanies,
    getReportCompanies,
  };
};
