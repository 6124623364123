import axiosInstance from "@app/axiosInstance";
import { T_ReportForm } from "@app/entities/report/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

export const useReportMutations = () => {
  const queryClient = useQueryClient();
  const idempotencyId = crypto.randomUUID();

  const { mutate: claimSwarm, isPending: isClaimSwarmPending } = useMutation({
    mutationFn: (reportId: string) =>
      axiosInstance.get(`/member/report/${reportId}/claim`),
    onSuccess: () => {
      notification.success({
        description:
          "You should receive a text message shortly with swarm location details.",
        message: "Successfully claimed report!",
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("reports"),
      });
    },
    onError: (error) => {
      // TODO: @withakerik - Add error handling for failed claim. (we don't know what error.message is or if it even exists)
      notification.error({
        description: error.message,
        message: "Failed to claim report.",
      });
    },
  });

  const { mutate: submitReport, isPending: isSubmitReportPending } =
    useMutation({
      mutationKey: ["submitReport"],
      mutationFn: (report: T_ReportForm & { recaptchaToken: string }) =>
        axiosInstance.post("/report", { ...report, idempotencyId }),
    });

  const isMutationPending = isClaimSwarmPending || isSubmitReportPending;
  return {
    isMutationPending,
    claimSwarm,
    submitReport,
  };
};
