import { useMemberMutations } from "@app/entities/member/mutations";
import type { AlertProps } from "antd/lib/alert";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const isLive = ["production", "staging"].includes(process.env.NODE_ENV ?? "");

export const useLoginLoader = () => {
  const [email, setEmail] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [refreshReCaptchaToken, setRefreshReCaptchaToken] = useState(false);
  const [alert, setAlert] = useState<AlertProps>({
    message: "",
    type: undefined,
  });
  const { sendMagicLink, isMutationPending } = useMemberMutations();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setRefreshReCaptchaToken((state) => !state);
    return () => setRefreshReCaptchaToken((state) => !state);
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("login_member");
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  const handleLogin = async (strategy: "google" | "magic-login") => {
    setRefreshReCaptchaToken((state) => !state);
    if (strategy === "magic-login") {
      if (!email) {
        return setAlert({ message: "Please enter an email", type: "warning" });
      }
      return sendMagicLink({ email, recaptchaToken });
    }

    const path = `/api/oauth/${strategy}/member`;
    const url = isLive ? path : `http://localhost:8335${path}`;
    window.location.href = url;
  };

  const isLoading = isMutationPending;
  return {
    handleLogin,
    handleReCaptchaVerify,
    setEmail,
    isLoading,
    refreshReCaptchaToken,
    alert,
    email,
  };
};
