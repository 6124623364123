import axiosInstance from "@app/axiosInstance";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

import { AxiosError } from "axios";

export const useCompanyMutations = () => {
  const queryClient = useQueryClient();

  const { mutate: addMemberToCompany, isPending: isAddMemberToCompanyPending } =
    useMutation({
      mutationFn: (memberActivationCode: string) =>
        axiosInstance.post(`/member/company`, { memberActivationCode }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate(query) {
            return (
              query.queryKey.includes("reports") ||
              query.queryKey.includes("companies")
            );
          },
        });
        return notification.success({
          message: "Success",
          description: "Successfully joined association.",
        });
      },
      onError: (error: AxiosError) => {
        switch (error?.response?.status) {
          case 404:
            return notification.error({
              message: "Error",
              description: "Invalid activation code.",
            });
          case 409:
            return notification.error({
              message: "Error",
              description: "You are already a member of this association.",
            });
          default:
            notification.error({
              message: "Error",
              description: "Something went wrong while joining association.",
            });
        }
      },
    });

  const isMutationPending = isAddMemberToCompanyPending;
  return {
    addMemberToCompany,
    isMutationPending,
  };
};
